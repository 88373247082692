<template>
    <div class="AllocateCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            ref="createForm"
            :inline="true"
            :model="form"
            :rules="rules"
            size="small"
            label-width="80px"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="类型" prop="allocateType">
                            <el-select
                                placeholder="请选择"
                                v-model="form.allocateType"
                                @change="reloadAllocateInOutDepts"
                            >
                                <el-option label="内部调拨" :value="1" />
                                <el-option label="自建调拨" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-select
                                v-model="deptGroupCode"
                                @change="reloadAllocateInOutDepts"
                                filterable
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="item in managedDeptGroups"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调入机构" prop="deptCode">
                            <el-select
                                v-model="form.deptCode"
                                ref="inDeptSelect"
                                :disabled="disableAllocateInDept"
                                filterable
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in allocateInDepts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="调出机构" prop="outDeptCode">
                            <el-select
                                v-model="form.outDeptCode"
                                ref="outDeptSelect"
                                :disabled="disableAllocateOutDept"
                                filterable
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in allocateOutDepts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="createTime" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="this.$store.state.session.name" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="showCustomer">
                    <el-col :span="8">
                        <el-form-item label="客户" prop="customer">
                            <el-input v-model="form.customer" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户电话" prop="customerMobile">
                            <el-input v-model="form.customerMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址" prop="customerAddress">
                            <el-input v-model="form.customerAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加
                </el-button>
                <el-button type="primary" @click="showPickUpGoods" size="small" :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息
                </el-button>
                <el-button
                    type="primary"
                    :loading="saveLoading"
                    @click="handleSave"
                    size="small"
                    :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="520"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品编码" width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.goodsCode'"
                                :rules="rules.goodsCode"
                            >
                                {{ scope.row.goodsCode }}
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="150">
                        <template slot-scope="scope">
                            {{
                                skuByGoodsCode(scope.row.goodsCode)
                                    .bars.map((d) => d)
                                    .join(' / ')
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | goodsType }}
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="自编码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).customBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="调拨价" width="170" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="2"
                                    @change="computeThenChangeMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="调拨数量" width="170">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                    @change="computeThenChangeMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="调拨金额" width="150" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <span>{{ scope.row.showMoney }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <pickUpGoods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
        </el-form>
    </div>
</template>

<script>
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import MoneyUtils from 'js/MoneyUtils';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import EfPriceInput from 'components/EfPriceInput';
import {
    goodsCodeValidateRule,
    mobileValidateRule,
    pricesValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import { postByFormData } from 'request/http';

export default {
    name: 'AllocateCreate',
    components: { PickUpGoods, EfRemark, EfStockBizCountInput, EfPriceInput },
    data() {
        return {
            routerName: 'menu.stock.allocate.create',
            search: '',
            managedDeptGroups: [],
            deptGroupCode: '',
            managedDepts: [],
            allocateInDepts: [],
            allocateOutDepts: [],
            deptName: '',
            form: {
                deptCode: '',
                outDeptCode: '',
                allocateType: null,
                remark: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
            },
            createTime: Util.nowFormatDate(),
            goodsDetailMap: new Map(),
            detailParamPropertyArrForAdd: [
                'goodsCode',
                new PropertyConverter(null, 'codes'),
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(null, 'showMoney'),
                new PropertyConverter('wholeSalePrice', 'showPrices', (wholeSalePrice) => {
                    //将所选商品的批发价作为默认价格
                    return MoneyUtils.moneyToYuan(wholeSalePrice);
                }),
            ],
            detailParamPropertyArrForSubmit: [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter('showPrices', 'prices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ],
            rules: {
                allocateType: { required: true, message: '请选择调拨类型', trigger: ['blur'] },
                customer: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customerMobile: mobileValidateRule(false),
                customerAddress: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa50-9]{2,30}$/,
                    message: '请正确输入客户地址',
                    trigger: ['blur'],
                },
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                showPrices: pricesValidateRule(),
                counts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    countGreaterThanZeroValidateRule(),
                ],
            },
            loadingDeptGroupFlag: true,
            meta: {
                belongGroup: '',
                groups: [],
                deptsInGroup: [],

                staffBelongDepts: [],
            },
            //有初始机构
            haveInitDept: false,
            haveInitOutDept: false,
            saveLoading: false,
        };
    },
    mounted() {
        this.$efApi.deptGroupApi.managedAllDeptGroups().then((rst) => {
            this.managedDeptGroups = rst;
            this.loadingDeptGroupFlag = false;
        });
        this.$efApi.deptApi.managedAllDepts().then((rst) => {
            this.managedDepts = rst;
        });
    },
    watch: {
        'form.deptCode': function (newDeptCode, oldDeptCode) {
            if (this.haveInitDept) {
                this.haveInitDept = false;
            } else {
                //清空商品列表数据
                this.form.detailParams = [];
            }
            if (this.form.allocateType === 1 && !Util.isEmpty(newDeptCode) && newDeptCode === this.form.outDeptCode) {
                //内部调拨，调入和调出机构不能相同
                this.$message.error('调入机构和调出机构不能相同');
                this.form.outDeptCode = '';
            }
        },
        'form.outDeptCode': function (newDeptCode, oldDeptCode) {
            if (this.haveInitOutDept) {
                this.haveInitOutDept = false;
            } else {
                //清空商品列表数据
                this.form.detailParams = [];
            }
            if (this.form.allocateType === 1 && !Util.isEmpty(newDeptCode) && newDeptCode === this.form.deptCode) {
                //内部调拨，调入和调出机构不能相同
                this.$message.error('调入机构和调出机构不能相同');
                this.form.deptCode = '';
            }
        },
        $route: {
            immediate: true,
            handler: function (to, from) {
                if (to.name == this.routerName) {
                    this.initGoodsSelectData(to.params);
                }
            },
        },
    },
    computed: {
        disableAllocateInDept() {
            return this.form.allocateType === 2 && !Util.isEmpty(this.form.outDeptCode);
        },
        disableAllocateOutDept() {
            return this.form.allocateType === 2 && !Util.isEmpty(this.form.deptCode);
        },
        showCustomer() {
            return !Util.isEmpty(this.form.outDeptCode);
        },
        disableQueryAndAddButton() {
            return this.disableShowPickUpGoodsDialog || Util.isEmpty(this.search);
        },
        disableShowPickUpGoodsDialog() {
            return !(
                (this.form.allocateType === 1 &&
                    !Util.isEmpty(this.form.deptCode) &&
                    !Util.isEmpty(this.form.outDeptCode)) ||
                (this.form.allocateType === 2 &&
                    (!Util.isEmpty(this.form.outDeptCode) || !Util.isEmpty(this.form.deptCode)))
            );
        },
        disableSaveButton() {
            return (
                this.disableShowPickUpGoodsDialog ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        //重新加载调入调出机构列表
        async reloadAllocateInOutDepts() {
            const allocateType = this.form.allocateType;
            const deptGroupCode = this.deptGroupCode;
            if (Util.isEmpty(allocateType) || Util.isEmpty(deptGroupCode)) {
                //清空调入调出机构列表
                this.allocateInDepts = [];
                this.allocateOutDepts = [];
            } else {
                //查询机构组下的机构列表
                const deptGroupRelatedDepts = await this.$efApi.deptApi.companyDeptGroupRelatedDepts(deptGroupCode);
                const managedDeptsFordeptGroup = this.managedDepts.filter((e) => {
                    return (
                        deptGroupRelatedDepts.find((e1) => {
                            return e1.code === e.code;
                        }) !== undefined
                    );
                });
                this.allocateInDepts = JSON.parse(JSON.stringify(managedDeptsFordeptGroup));
                if (allocateType === 1) {
                    //内部调拨，调出机构列表为机构组下排除掉调入机构后的机构列表
                    this.allocateOutDepts = JSON.parse(JSON.stringify(deptGroupRelatedDepts));
                } else {
                    //自建调拨，调出机构列表为机构组下机构列表与当前员工管理或所属机构列表的交集
                    this.allocateOutDepts = JSON.parse(JSON.stringify(managedDeptsFordeptGroup));
                }
            }
            this.form.deptCode = '';
            this.form.outDeptCode = '';
        },
        pickUpGoodsQueryParam() {
            if (this.disableShowPickUpGoodsDialog) {
                return null;
            }
            let deptCode;
            let deptName;
            if (!Util.isEmpty(this.form.outDeptCode)) {
                deptCode = this.form.outDeptCode;
                deptName = this.$refs.outDeptSelect.selected.label;
            } else {
                deptCode = this.form.deptCode;
                deptName = this.$refs.inDeptSelect.selected.label;
            }
            return {
                deptCode: deptCode,
                deptName: deptName,
                goodsStatusIn: [0, 1],
                page: 1,
                limit: 100,
                showPricePropertyObj: {
                    factoryPrice: false,
                    wholeSalePrice: true,
                    retailPrice: false,
                    planCostPrice: false,
                    wmaCostPrice: false,
                },
            };
        },
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        showPickUpGoods() {
            const pickUpGoodsQueryParam = this.pickUpGoodsQueryParam();
            if (pickUpGoodsQueryParam == null) {
                return;
            }
            this.$refs.pickUpGoods.show(pickUpGoodsQueryParam, pickUpGoodsQueryParam.deptName);
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.disableShowPickUpGoodsDialog) {
                    this.$message.error('请选择机构');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                this.saveLoading = true;
                //将商品详情列表转换成多字段数组形式
                const createParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit
                    ),
                };
                //删除非提交必要字段数据
                delete createParam.detailParams;
                await postByFormData(`/stock/allocate/createExtend`, createParam).finally(() => {
                    this.saveLoading = false;
                });
                this.$message.success('保存成功');
                this.goBackAndReload();
            });
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        computeThenChangeMoney(row) {
            row.showMoney = MoneyUtils.moneyToFour(
                MoneyUtils.moneyToDb(
                    this.decimal(row.counts || 0)
                        .mul(row.showPrices || 0)
                        .toNumber()
                )
            );
        },
        initGoodsSelectData(params) {
            this.$nextTick(() => {
                if (Object.keys(params).length > 0) {
                    (async () => {
                        this.deptGroupCode = params.deptGroupCode;
                        this.form.allocateType = params.allocateType ? 1 : 2;
                        await this.reloadAllocateInOutDepts();
                        if (params.allocateType) {
                            //内部调拨
                            this.haveInitDept = true;
                            this.haveInitOutDept = true;
                            if (params.deptAllocateType) {
                                this.form.outDeptCode = params.goodsPageDeptCode;
                                this.form.deptCode = params.otherDeptCode;
                            } else {
                                this.form.deptCode = params.goodsPageDeptCode;
                                this.form.outDeptCode = params.otherDeptCode;
                            }
                        } else {
                            if (params.deptAllocateType) {
                                //当前门店为调出机构
                                this.haveInitOutDept = true;
                                this.form.outDeptCode = params.goodsPageDeptCode;
                            } else {
                                this.haveInitDept = true;
                                this.form.deptCode = params.goodsPageDeptCode;
                            }
                        }
                        this.setSelectedGoods(params.goodsPageSelect);
                    })();
                } else {
                    this.haveInitDept = false;
                    this.haveInitOutDept = false;
                    this.form.allocateType = '';
                    this.form.deptCode = '';
                    this.form.outDeptCode = '';
                }
            });
        },
    },
};
</script>
